<template>
  <el-dialog
    class="preview-bill-pdf-dialog"
    title="预览电子票据"
    :visible.sync="show"
    @close="closeDialog"
    width="1025px">
    <div class="pdf-content" id="pdf">
      <pdf ref="pdf" :src="pdfSrc" />
    </div>
    <template #footer>
      <div style="text-align: center">
        <el-button type="primary" @click="printBill">打印</el-button>
        <el-button @click="closeDialog">关闭</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import dialog from '@/vue/mixins/dialog'
// 从 vue-pdf-signature 引入 用于解决汉字乱码的问题
import pdf from 'vue-pdf-signature'
import CMapReaderFactory from 'vue-pdf-signature/src/CMapReaderFactory'
import printJS from 'print-js'

export default {
  name: 'previewBillPdfDialog',
  mixins: [dialog],
  components: {
    pdf
  },
  props: {
    url: String
  },
  data () {
    return {
      printObj: {
        url: ''
      },
      imagePath: ''
    }
  },
  computed: {
    pdfSrc () {
      // CMapReaderFactory 解决汉字乱码or缺失
      return pdf.createLoadingTask({
        url: this.url,
        cMapPacked: true,
        CMapReaderFactory
      })
    }
  },
  methods: {
    printBill () {
      printJS({
        printable: 'pdf',
        type: 'html',
        style: `@page{size: A4 portrait;margin: 0cm;resolution: 300dpi;}}
       #pdf{width: 2460px !important;height:1356px !important;}
        canvas{width: 100%!important;height: 100%!important; }
        `,
        scanStyles: false
      })
    },
    closeDialog () {
      this.$emit('update:url', '')
      this.close()
    }
  }
}
</script>
<style lang="scss">
.preview-bill-pdf-dialog {
  .el-dialog {
    margin-top: 10vh !important;
  }

  .el-dialog__body {
    padding: 0 0 20px;
    width: 100%;
    height: 568px;
    overflow: hidden;
  }

  /* pdf-content的数值直接影响生成图片的质量
  *  记得同步调整 printJS 里的style
  */
  .pdf-content {
    width: 2460px;
    height: 1356px;
    transform: scale(.4);
    transform-origin: 0 0;
  }

}
</style>
